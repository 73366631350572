import store from "@/state/store";

export default [
    {
        path: "/dashboard",
        name: "dashboard-admin",
        meta: {
            authRequired: true,
        },
        component: () => import("./views/dashboards/dashboard-admin"),
    },
    {
        path: "/404",
        name: "404",
        component: () => import("./views/404"),
    },
    {
        path: "/",
        name: "login",
        component: () => import("./views/account/login"),
    },
    {
        path: "/public/work-permit/:work_permit_id",
        name: "public-work-permit",
        component: () => import("./views/public/work-permit"),
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("./views/account/register"),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("./views/account/forgot-password"),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("./views/account/logout"),
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch("auth/logOut");
                } else {
                    store.dispatch("authfack/logout");
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some((route) => route.push("/login"));
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom });
            },
        },
    },

    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    // {
    //   path: "*",
    //   redirect: "404",
    // },
    // Router Admin
    {
        path: "/permohonan",
        name: "list-permohonan",
        meta: { authRequired: true },
        component: () => import("./views/permohonan/list-permohonan"),
    },
    {
        path: "/permohonan/detail/:permohonan_id",
        name: "admin-detail-permohonan",
        meta: { authRequired: true },
        component: () => import("./views/permohonan/detail-permohonan"),
    },
    {
        path: "/master/disclaimer",
        name: "all-disclaimer",
        meta: { authRequired: true },
        component: () => import("./views/master/disclaimer/all-disclaimer"),
    },
    {
        path: "/master/disclaimer/tambah",
        name: "add-disclaimer",
        meta: { authRequired: true },
        component: () => import("./views/master/disclaimer/add-disclaimer"),
    },
    {
        path: "/master/disclaimer/edit/:md_id",
        name: "edit-disclaimer",
        meta: { authRequired: true },
        component: () => import("./views/master/disclaimer/edit-disclaimer"),
    },
    {
        path: "/master/provinsi",
        name: "all-provinsi",
        meta: { authRequired: true },
        component: () => import("./views/master/provinsi/all-provinsi"),
    },
    {
        path: "/master/provinsi/tambah",
        name: "add-provinsi",
        meta: { authRequired: true },
        component: () => import("./views/master/provinsi/add-provinsi"),
    },
    {
        path: "/master/provinsi/edit/:id",
        name: "edit-provinsi",
        meta: { authRequired: true },
        component: () => import("./views/master/provinsi/edit-provinsi"),
    },
    {
        path: "/master/kota",
        name: "all-kota",
        meta: { authRequired: true },
        component: () => import("./views/master/kota/all-kota"),
    },
    {
        path: "/master/kota/tambah",
        name: "add-kota",
        meta: { authRequired: true },
        component: () => import("./views/master/kota/add-kota"),
    },
    {
        path: "/master/kota/edit/:kota_id",
        name: "edit-kota",
        meta: { authRequired: true },
        component: () => import("./views/master/kota/edit-kota"),
    },
    {
        path: "/master/kecamatan",
        name: "all-kecamatan",
        meta: { authRequired: true },
        component: () => import("./views/master/kecamatan/all-kecamatan"),
    },
    {
        path: "/master/kecamatan/tambah",
        name: "add-kecamatan",
        meta: { authRequired: true },
        component: () => import("./views/master/kecamatan/add-kecamatan"),
    },
    {
        path: "/master/kecamatan/edit/:kec_id",
        name: "edit-kecamatan",
        meta: { authRequired: true },
        component: () => import("./views/master/kecamatan/edit-kecamatan"),
    },

    {
        path: "/master/dokumen-persyaratan",
        name: "all-dokumen-persyaratan",
        meta: { authRequired: true },
        component: () => import("./views/master/dokumen-persyaratan/all-dokumen-persyaratan"),
    },
    {
        path: "/master/dokumen-persyaratan/tambah",
        name: "add-dokumen-persyaratan",
        meta: { authRequired: true },
        component: () => import("./views/master/dokumen-persyaratan/add-dokumen-persyaratan"),
    },
    {
        path: "/master/dokumen-persyaratan/edit/:mdp_id",
        name: "edit-dokumen-persyaratan",
        meta: { authRequired: true },
        component: () => import("./views/master/dokumen-persyaratan/edit-dokumen-persyaratan"),
    },
    {
        path: "/master/eselon",
        name: "all-eselon",
        meta: { authRequired: true },
        component: () => import("./views/master/eselon/all-eselon"),
    },
    {
        path: "/master/eselon/tambah",
        name: "add-eselon",
        meta: { authRequired: true },
        component: () => import("./views/master/eselon/add-eselon"),
    },
    {
        path: "/master/eselon/edit/:me_id",
        name: "edit-eselon",
        meta: { authRequired: true },
        component: () => import("./views/master/eselon/edit-eselon"),
    },
    {
        path: "/master/jenis-perizinan",
        name: "all-jenis-perizinan",
        meta: { authRequired: true },
        component: () => import("./views/master/jenis-perizinan/all-jenis-perizinan"),
    },
    {
        path: "/master/jenis-perizinan/tambah",
        name: "add-jenis-perizinan",
        meta: { authRequired: true },
        component: () => import("./views/master/jenis-perizinan/add-jenis-perizinan"),
    },
    {
        path: "/master/jenis-perizinan/edit/:mpj_id",
        name: "edit-jenis-perizinan",
        meta: { authRequired: true },
        component: () => import("./views/master/jenis-perizinan/edit-jenis-perizinan"),
    },
    {
        path: "/master/kbli",
        name: "all-kbli",
        meta: { authRequired: true },
        component: () => import("./views/master/kbli/all-kbli"),
    },
    {
        path: "/master/kbli/tambah",
        name: "add-kbli",
        meta: { authRequired: true },
        component: () => import("./views/master/kbli/add-kbli"),
    },
    {
        path: "/master/kbli/edit/:mk_id",
        name: "edit-kbli",
        meta: { authRequired: true },
        component: () => import("./views/master/kbli/edit-kbli"),
    },
    {
        path: "/master/negara",
        name: "all-negara",
        meta: { authRequired: true },
        component: () => import("./views/master/negara/all-negara"),
    },
    {
        path: "/master/negara/tambah",
        name: "add-negara",
        meta: { authRequired: true },
        component: () => import("./views/master/negara/add-negara"),
    },
    {
        path: "/master/negara/edit/:mn_id",
        name: "edit-negara",
        meta: { authRequired: true },
        component: () => import("./views/master/negara/edit-negara"),
    },
    {
        path: "/master/pelabuhan",
        name: "all-pelabuhan",
        meta: { authRequired: true },
        component: () => import("./views/master/pelabuhan/all-pelabuhan"),
    },
    {
        path: "/master/pelabuhan/tambah",
        name: "add-pelabuhan",
        meta: { authRequired: true },
        component: () => import("./views/master/pelabuhan/add-pelabuhan"),
    },
    {
        path: "/master/pelabuhan/edit/:mp_id",
        name: "edit-pelabuhan",
        meta: { authRequired: true },
        component: () => import("./views/master/pelabuhan/edit-pelabuhan"),
    },
    {
        path: "/master/perizinan",
        name: "all-perizinan",
        meta: { authRequired: true },
        component: () => import("./views/master/perizinan/all-perizinan"),
    },
    {
        path: "/master/perizinan/konfigurasi-kbli/:mp_id",
        name: "konfigurasi-kbli",
        meta: { authRequired: true },
        component: () => import("./views/master/perizinan/konfigurasi-kbli"),
    },
    {
        path: "/master/perizinan/tambah",
        name: "add-perizinan",
        meta: { authRequired: true },
        component: () => import("./views/master/perizinan/add-perizinan"),
    },
    {
        path: "/master/perizinan/edit/:mp_id",
        name: "edit-perizinan",
        meta: { authRequired: true },
        component: () => import("./views/master/perizinan/edit-perizinan"),
    },
    {
        path: "/master/satuan",
        name: "all-satuan",
        meta: { authRequired: true },
        component: () => import("./views/master/satuan/all-satuan"),
    },
    {
        path: "/master/satuan/tambah",
        name: "add-satuan",
        meta: { authRequired: true },
        component: () => import("./views/master/satuan/add-satuan"),
    },
    {
        path: "/master/satuan/edit/:ms_id",
        name: "edit-satuan",
        meta: { authRequired: true },
        component: () => import("./views/master/satuan/edit-satuan"),
    },
    {
        path: "/master/sub-perizinan",
        name: "all-sub-perizinan",
        meta: { authRequired: true },
        component: () => import("./views/master/sub-perizinan/all-sub-perizinan"),
    },
    {
        path: "/master/sub-perizinan/tambah",
        name: "add-sub-perizinan",
        meta: { authRequired: true },
        component: () => import("./views/master/sub-perizinan/add-sub-perizinan"),
    },
    {
        path: "/master/sub-perizinan/edit/:mps_id",
        name: "edit-sub-perizinan",
        meta: { authRequired: true },
        component: () => import("./views/master/sub-perizinan/edit-sub-perizinan"),
    },
    {
        path: "/master/sub-perizinan/edit/:mps_id",
        name: "edit-sub-perizinan",
        meta: { authRequired: true },
        component: () => import("./views/master/sub-perizinan/edit-sub-perizinan"),
    },
    {
        path: "/master/sub-perizinan/konfigurasi-dokumen-persyaratan/:mps_id",
        name: "konfigurasi-dokumen-persyaratan",
        meta: { authRequired: true },
        component: () => import("./views/master/sub-perizinan/konfigurasi-dokumen-persyaratan"),
    },
    {
        path: "/master/sub-perizinan/konfigurasi-disclaimer/:mps_id",
        name: "konfigurasi-disclaimer",
        meta: { authRequired: true },
        component: () => import("./views/master/sub-perizinan/konfigurasi-disclaimer"),
    },
    {
        path: "/master/sub-perizinan/konfigurasi-form/:mps_id",
        name: "konfigurasi-form",
        meta: { authRequired: true },
        component: () => import("./views/master/sub-perizinan/konfigurasi-form"),
    },
    {
        path: "/master/sub-perizinan/konfigurasi-surat-keputusan/:mps_id",
        name: "konfigurasi-surat-keputusan",
        meta: { authRequired: true },
        component: () => import("./views/master/sub-perizinan/konfigurasi-surat-keputusan"),
    },
    {
        path: "/master/sub-perizinan/preview-from",
        name: "preview-from",
        meta: { authRequired: true },
        component: () => import("./views/master/sub-perizinan/preview-from"),
    },
    {
        path: "/master/sub-perizinan/konfigurasi-matrix-approval/:mps_id",
        name: "konfigurasi-matrix-approval",
        meta: { authRequired: true },
        component: () => import("./views/master/sub-perizinan/konfigurasi-matrix-approval"),
    },

    {
        path: "/master/status-permohonan",
        name: "all-status-permohonan",
        meta: { authRequired: true },
        component: () => import("./views/master/status-permohonan/all-status-permohonan"),
    },
    {
        path: "/master/status-permohonan/tambah",
        name: "add-status-permohonan",
        meta: { authRequired: true },
        component: () => import("./views/master/status-permohonan/add-status-permohonan"),
    },
    {
        path: "/master/status-permohonan/edit/:msp_id",
        name: "edit-status-permohonan",
        meta: { authRequired: true },
        component: () => import("./views/master/status-permohonan/edit-status-permohonan"),
    },
    {
        path: "/master/tipe-pemohon",
        name: "all-tipe-pemohon",
        meta: { authRequired: true },
        component: () => import("./views/master/tipe-pemohon/all-tipe-pemohon"),
    },
    {
        path: "/master/tipe-pemohon/tambah",
        name: "add-tipe-pemohon",
        meta: { authRequired: true },
        component: () => import("./views/master/tipe-pemohon/add-tipe-pemohon"),
    },
    {
        path: "/master/tipe-pemohon/edit/:mtp_id",
        name: "edit-tipe-pemohon",
        meta: { authRequired: true },
        component: () => import("./views/master/tipe-pemohon/edit-tipe-pemohon"),
    },
    // role
    {
        path: "/master/role",
        name: "all-role",
        meta: { authRequired: true },
        component: () => import("./views/master/role/all-role"),
    },
    {
        path: "/master/role/tambah",
        name: "add-role",
        meta: { authRequired: true },
        component: () => import("./views/master/role/add-role"),
    },
    {
        path: "/master/role/edit/:id",
        name: "edit-role",
        meta: { authRequired: true },
        component: () => import("./views/master/role/edit-role"),
    },
    // department
    {
        path: "/master/department",
        name: "all-department",
        meta: { authRequired: true },
        component: () => import("./views/master/department/all-department"),
    },
    {
        path: "/master/department/tambah",
        name: "add-department",
        meta: { authRequired: true },
        component: () => import("./views/master/department/add-department"),
    },
    {
        path: "/master/department/edit/:mdep_id",
        name: "edit-department",
        meta: { authRequired: true },
        component: () => import("./views/master/department/edit-department"),
    },
    // division
    {
        path: "/master/division",
        name: "all-division",
        meta: { authRequired: true },
        component: () => import("./views/master/division/all-division"),
    },
    {
        path: "/master/division/tambah",
        name: "add-division",
        meta: { authRequired: true },
        component: () => import("./views/master/division/add-division"),
    },
    {
        path: "/master/division/edit/:mdiv_id",
        name: "edit-division",
        meta: { authRequired: true },
        component: () => import("./views/master/division/edit-division"),
    },
    // user
    {
        path: "/master/user",
        name: "all-user",
        meta: { authRequired: true },
        component: () => import("./views/master/user/all-user"),
    },
    {
        path: "/master/user/tambah",
        name: "add-user",
        meta: { authRequired: true },
        component: () => import("./views/master/user/add-user"),
    },
    {
        path: "/master/user/edit/:idUser",
        name: "edit-user",
        meta: { authRequired: true },
        component: () => import("./views/master/user/edit-user"),
    },
    {
        path: "/user/profile",
        name: "profile-user",
        meta: { authRequired: true },
        component: () => import("./views/master/user/profile-user"),
    },
    // perusahaan
    {
        path: "/master/perusahaan",
        name: "perusahaan",
        meta: { authRequired: true },
        component: () => import("./views/master/perusahaan/all-perusahaan"),
    },
    // Router Pemohon
    {
        path: "/pemohon/dashboard",
        name: "pemohon-dashboard",
        meta: { authRequired: true },
        component: () => import("./views/pemohon/dashboard-pemohon"),
    },
    {
        path: "/pemohon/profile",
        name: "pemohon-profile",
        meta: { authRequired: true },
        component: () => import("./views/pemohon/profile-pemohon"),
    },
    {
        path: "/loading",
        name: "loading",

        component: () => import("./views/sso/loading"),
    },
    {
        path: "/oss/login",
        name: "oss-login",

        component: () => import("./views/sso/loading-oss"),
    },
    {
        path: "/pemohon/daftar-permohonan",
        name: "daftar-permohonan",
        meta: { authRequired: true },
        component: () => import("./views/pemohon/permohonan/list-permohonan"),
    },
    {
        path: "/pemohon/daftar-id-izin",
        name: "pemohon-daftar-id-izin",
        meta: { authRequired: true },
        component: () => import("./views/pemohon/daftar-id-izin/all-daftar-id-izin"),
    },
    {
        path: "/pemohon/permohonan/add-permohonan/",
        name: "add-permohonan",
        meta: { authRequired: true },
        component: () => import("./views/pemohon/permohonan/add-permohonan"),
    },
    {
        path: "/pemohon/detail-permohonan/:permohonan_id",
        name: "pemohon-detail-permohonan",
        meta: { authRequired: true },
        component: () => import("./views/pemohon/permohonan/detail-permohonan"),
    },
    {
        path: "/pemohon/edit-permohonan/:permohonan_id",
        name: "pemohon-edit-permohonan",
        meta: { authRequired: true },
        component: () => import("./views/pemohon/permohonan/edit-permohonan"),
    },
    {
        path: "/pemohon/edit-perbaiki-permohonan/:permohonan_id",
        name: "pemohon-edit-perbaiki-permohonan",
        meta: { authRequired: true },
        component: () => import("./views/pemohon/permohonan/edit-perbaiki-permohonan"),
    },
    {
        path: "/laporan/laporan-permohonan",
        name: "laporan-permohonan",
        meta: { authRequired: true },
        component: () => import("./views/laporan/laporan-permohonan"),
    },
    // work permit
    {
        path: "/permit/request",
        name: "permit-request",
        meta: { authRequired: true },
        component: () => import("./views/work-permit/work-permit-request"),
    },
    {
        path: "/permit/add",
        name: "add-work-permit",
        meta: { authRequired: true },
        component: () => import("./views/work-permit/add-work-permit"),
    },
    {
        path: "/permit/tracking",
        name: "work-permit-tracking",
        meta: { authRequired: true },
        component: () => import("./views/work-permit/work-permit-tracking"),
    },
    {
        path: "/permit/closing",
        name: "work-permit-closing",
        meta: { authRequired: true },
        component: () => import("./views/work-permit/work-permit-closing"),
    },
    {
        path: "/permit/closing/add",
        name: "add-work-permit-closing",
        meta: { authRequired: true },
        component: () => import("./views/work-permit/add-work-permit-closing"),
    },
    {
        path: "/permit/closing/edit/:closingPermitId",
        name: "edit-work-permit-closing",
        meta: { authRequired: true },
        component: () => import("./views/work-permit/edit-work-permit-closing"),
    },
    {
        path: "/permit/detail/:permitId",
        name: "work-permit-detail",
        meta: { authRequired: true },
        component: () => import("./views/work-permit/work-permit-detail"),
    },
    {
        path: "/permit/detail-tracking/:permitId/:is_tracking",
        name: "work-permit-detail-tracking",
        meta: { authRequired: true },
        component: () => import("./views/work-permit/work-permit-detail"),
    },
    {
        path: "/permit/edit/:permitId",
        name: "edit-work-permit",
        meta: { authRequired: true },
        component: () => import("./views/work-permit/edit-work-permit"),
    },
    // update current work plan
    {
        path: "/permit/update/:permitId",
        name: "update-work-permit",
        meta: { authRequired: true },
        component: () => import("./views/work-permit/update-work-permit"),
    },
    // config
    {
        path: "/config/work-permit",
        name: "work-permit-config",
        meta: { authRequired: true },
        component: () => import("./views/config/work-permit/config-permit"),
    },
];
