import { createWebHistory, createRouter } from "vue-router";

import routes from "./routes";

const router = createRouter({
    history: createWebHistory(),
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    mode: "hash",
    // Simulate native-like scroll behavior when navigating to a new
    // route and using back/forward buttons.
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

// Before each route evaluates...
// router.beforeEach((routeTo, routeFrom, next) => {
//   const publicPages = [
//     "/login",
//     "/register",
//     "/forgot-password",
//     "/loading",
//     "/oss/login",
//   ];
//   const authpage = !publicPages.includes(routeTo.path);
//   const aksesToken = localStorage.getItem("access_token");
//   console.log(aksesToken);
//   if (authpage && !aksesToken) {
//     return "/login";
//   }

//   next();
// });

router.beforeEach((routeTo, routeFrom, next) => {
    const aksesToken = localStorage.getItem("access_token");
    console.log(aksesToken);
    console.log(routeFrom);
    const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
    if (
        // make sure the user is authenticated
        !aksesToken &&
        authRequired &&
        // ❗️ Avoid an infinite redirect
        routeTo.name !== "login" &&
        routeTo.name !== "loading" &&
        routeTo.name !== "oss-login"
    ) {
        // redirect the user to the login page
        return next("/");
    }
    next();
});

export default router;
